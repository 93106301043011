import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `

<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">AnnotationToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> AnnotationToolbar.hidesWithEditToolbar</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> AnnotationToolbar</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>FreeHandCreateDelegate, FreehandCreateToolbarDelegate</td></tr>
<tr><th>To</th><td>EditToolbarDelegate, FreeHandCreateDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">AnnotationViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def XE_CLOUD</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">AnnotEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> AnnotEditTool.minimumAnnotationSize</div>
<div class="difference"><span class="status added">Added</span> AnnotEditTool.maximumAnnotationSize</div>
<div class="difference"><span class="status added">Added</span> -[AnnotEditTool boundPointToPage:]</div>
<div class="difference"><span class="status added">Added</span> -[AnnotEditTool boundPageRect:toPage:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">CloudCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> CloudCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">CreateToolBase.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> CreateToolBase.annotType</div>
<div class="difference"><span class="status added">Added</span> CreateToolBase.requiresEditSupport</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[CreateToolBase annotClass]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull Class)annotClass</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, strong, nonnull) Class annotClass</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">EditToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> EditToolbarDelegate</div>
<div class="difference"><span class="status added">Added</span> -[EditToolbarDelegate editToolbarUndoChange:]</div>
<div class="difference"><span class="status added">Added</span> -[EditToolbarDelegate editToolbarRedoChange:]</div>
<div class="difference"><span class="status added">Added</span> -[EditToolbarDelegate editToolbarDidCancel:]</div>
<div class="difference"><span class="status added">Added</span> -[EditToolbarDelegate editToolbarDidCommit:]</div>
<div class="difference"><span class="status added">Added</span> EditToolbar</div>
<div class="difference"><span class="status added">Added</span> EditToolbar.delegate</div>
<div class="difference"><span class="status added">Added</span> EditToolbar.undoRedoHidden</div>
<div class="difference"><span class="status added">Added</span> EditToolbar.undoEnabled</div>
<div class="difference"><span class="status added">Added</span> EditToolbar.redoEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PolygonCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PolygonCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PolylineCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PolylineCreate</div>
<div class="difference"><span class="status added">Added</span> PolylineCreate.vertices</div>
<div class="difference"><span class="status added">Added</span> -[PolylineCreate commitAnnotation]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PolylineEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PolylineEditTool</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">ThumbnailsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ThumbnailsViewController.editingEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[Tool didSelectAnnotation:onPageNumber:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">ToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTToolManager tool:didSelectAnnotation:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManagerDelegate toolManager:didSelectAnnotation:onPageNumber:]</div>
</div>

</div>
</body>
</html>


`


export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}

